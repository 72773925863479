<template>
  <v-card class="mt-1" tile>
    <v-card-text style="height: auto" class="py-0 px-0 d-flex pr-1">
      <div
        style="width: 12px; flex-grow: 1; cursor: move"
        class="handle d-flex align-center"
        :style="{ backgroundColor: color }"
      >
        <v-icon color="white" size="12px">{{ icons.mdiArrowUpDown }}</v-icon>
      </div>
      <div style="width: calc(100% - 10px); height: 100%" class="pl-2">
        <div class="d-flex">
          <span
            :title="name"
            class="d-block"
            style="
              width: 161px;
              margin-left: 2px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 13px;
            "
            >{{ name }}</span
          >
          <div style="margin-left: auto">
            <v-icon v-if="legend.length" @click="showLegend = !showLegend" title="Toggle Legend">{{
              showLegend ? icons.mdiChevronUp : icons.mdiChevronDown
            }}</v-icon>
            <v-icon title="Zoom to layer" size="18" @click="$emit('on-zoom')" class="ml-2">{{
              icons.mdiCrosshairs
            }}</v-icon>
            <v-icon
              :disabled="compareMode !== 'overlay'"
              class="ml-2"
              title="Hide/show layer"
              size="18"
              @click="visible = !visible"
              >{{ visible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline }}</v-icon
            >

            <v-icon class="ml-2" title="Delete layer" size="18" @click="$emit('on-delete')">{{
              icons.mdiDeleteOutline
            }}</v-icon>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :disabled="actions.length === 0" size="18" class="ml-2" v-bind="attrs" v-on="on">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </template>
              <v-list dense>
                <v-list-item v-if="actions.includes('table-attribute')" @click="$emit('on-show-table-attribute')">
                  <v-list-item-title
                    ><v-icon size="20">{{ icons.mdiTable }}</v-icon
                    ><span class="ml-2">Table Attribute</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actions.includes('style-control')" @click="$emit('on-show-style-control')">
                  <v-list-item-title
                    ><v-icon size="20">{{ icons.mdiBorderStyle }}</v-icon
                    ><span class="ml-2">Style</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actions.includes('download')" @click="download">
                  <v-list-item-title
                    ><v-icon size="20">{{ icons.mdiDownload }}</v-icon
                    ><span class="ml-2">Download</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="d-flex mt-1 align-center">
          <div style="width: 70%">
            <v-slider dense hide-details :color="color" v-model="opacity" always-dirty track-color="grey">
              <template v-slot:prepend>
                <div v-if="layer.get('type') === 'wms'" class="ws-icon wms" :style="{ backgroundColor: color }"></div>
                <div
                  v-else-if="layer.get('type') === 'wfs'"
                  class="ws-icon wfs"
                  :style="{ backgroundColor: color }"
                ></div>
                <v-icon v-else :color="color"> {{ layer.getProperties() | layerType }} </v-icon>
              </template>
            </v-slider>
          </div>

          <v-btn-toggle
            v-if="compareMode !== 'overlay'"
            color="primary"
            borderless
            dense
            group
            multiple
            v-model="position"
            class="align-center ml-auto btn-position"
          >
            <v-btn value="L" x-small> L </v-btn>
            <v-btn value="R" x-small> R </v-btn>
          </v-btn-toggle>
        </div>

        <div v-if="showLegend">
          <div v-for="item in legend" :key="item.name">
            <v-divider></v-divider>
            <div class="d-flex pl-5 align-center">
              <div class="rounded-sm" style="height: 10px; width: 30px" :style="{ backgroundColor: item.color }" />
              <span class="ml-5">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  mdiEyeOutline,
  mdiVectorPolygon,
  mdiArrowUpDown,
  mdiDotsVertical,
  mdiEyeOffOutline,
  mdiDeleteOutline,
  mdiCrosshairs,
  mdiDownload,
  mdiTable,
  mdiBorderStyle,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js'
import axios from 'axios'
import Layer from 'ol/layer/Layer'
export default {
  props: {
    layer: Layer,
    compareMode: String,
  },
  created() {
    this._computedWatchers.position.run()
  },
  data() {
    return {
      showLegend: false,
      icons: {
        mdiEyeOutline,
        mdiVectorPolygon,
        mdiArrowUpDown,
        mdiDotsVertical,
        mdiEyeOffOutline,
        mdiDeleteOutline,
        mdiCrosshairs,
        mdiDownload,
        mdiTable,
        mdiBorderStyle,
        mdiChevronDown,
        mdiChevronUp,
      },
    }
  },
  watch: {
    compareMode() {
      this.resetPosition()
    },
  },
  methods: {
    async download() {
      if (this.layer.get('type') === 'wfs') {
        this.$loader(true)
        try {
          const params = {
            service: 'WFS',
            version: '2.0.0',
            typename: this.layer.get('layer').Name,
            outputFormat: 'SHAPE-ZIP',
            request: 'GetFeature',
          }
          const headers = this.layer.get('credentials')
            ? {
                Authorization: `Basic ${btoa(
                  `${this.layer.get('credentials').username}:${this.layer.get('credentials').password}`,
                )}`,
              }
            : {}
          const { data } = await axios.get(this.layer.get('url'), { params, headers, responseType: 'blob' })
          const url = URL.createObjectURL(
            new Blob([data], {
              type: 'application/zip',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.layer.get('name')}.zip`)
          document.body.appendChild(link)
          link.click()
        } finally {
          this.$loader(false)
        }
      } else window.open(`/api/layers/${this.layer.get('id')}/download`, '_blank')
    },

    resetPosition() {
      switch (this.compareMode) {
        case 'slide':
          this.position = ['L', 'R']
          break
        case 'sync':
          this.position = ['R']
        default:
          break
      }
    },
  },

  computed: {
    name() {
      return this.layer.get('name')
    },
    color() {
      return this.layer.get('color')
    },

    opacity: {
      get() {
        return this.layer.getOpacity() * 100
      },
      set(value) {
        this.layer.setOpacity(value / 100)
      },
    },

    position: {
      get() {
        return this.layer.get('position') || (this.compareMode === 'slide' ? ['L', 'R'] : ['R'])
      },
      set(position) {
        this.layer.setProperties({ position })
        this.$emit('on-change-position', position)
        this._computedWatchers.position.run()
      },
    },

    visible: {
      get() {
        return this.layer.getVisible()
      },
      set(value) {
        this.layer.setVisible(value)
      },
    },

    legend() {
      return this.layer.get('legend') || []
    },

    supportedStyleControl() {
      return this.layer.get('supportedStyleControl')
    },

    supportedTableAttribute() {
      return this.layer.get('supportedTableAttribute')
    },

    actions() {
      return this.layer.get('actions')
    },

    zIndex: {
      get() {
        return this.layer.getZIndex()
      },
      set(value) {
        return this.layer.setZIndex(value)
      },
    },
  },
}
</script>

<style>
.btn-position {
  height: 20px !important;
  min-width: 20px !important;
  padding: 0 0 !important;
  font-size: 0.625rem !important;
  margin: 0 !important;
  margin-right: 3px !important;
}

.wfs {
  mask: url(/icons/wfs.svg) no-repeat center / contain;
  -webkit-mask: url(/icons/wfs.svg) no-repeat center / contain;
}
.wms {
  mask: url(/icons/wms.svg) no-repeat center / contain;
  -webkit-mask: url(/icons/wms.svg) no-repeat center / contain;
}
.ws-icon {
  width: 24px;
  height: 24px;
}
</style>
