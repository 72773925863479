<template>
  <div class="map-control" :style="{ top, left, right, bottom }">
    <div class="ol-control" style="position: relative">
      <button title="Toggle my location" icon>
        <v-icon color="black" size="20" @click="setTracking(!tracking)">{{
          tracking ? icons.mdiCrosshairsOff : icons.mdiCrosshairsGps
        }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiCrosshairsGps, mdiCrosshairsOff } from '@mdi/js'
import { Feature } from 'ol'
import Geolocation from 'ol/Geolocation.js'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

export default {
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
  inject: ['map'],
  data() {
    return {
      icons: { mdiCrosshairsGps, mdiCrosshairsOff },
      geolocation: null,
      tracking: false,
      source: null,
    }
  },

  methods: {
    init() {},

    onPositionChange() {
      this.source.clear()
      const position = this.geolocation.getPosition()
      this.source.addFeature(new Feature(new Point(position)))
      if (!this.tracking) {
        this.map.getView().setCenter(position)
        this.tracking = true
      }
    },

    setTracking(value) {
      this.geolocation.setTracking(value)
      if (value) {
        this.geolocation.on('change:position', this.onPositionChange)
      } else {
        this.tracking = false
        this.source.clear()
        this.geolocation.un('change:position', this.onPositionChange)
      }
    },
  },

  mounted() {
    this.source = new VectorSource()
    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.map.getView().getProjection(),
    })
    new VectorLayer({
      map: this.map,
      source: this.source,
      style: {
        'circle-fill-color': '#3399CC',
        'circle-stroke-color': '#fff',
        'circle-stroke-width': 2,
        'circle-radius': 6,
      },
    })
  },
}
</script>
