<template>
  <v-dialog :value="showDialog" max-width="500" persistent>
    <v-card>
      <v-card-title>
        <span>Create Web Service Layer </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isValid" ref="form">
          <v-select
            label="Type"
            v-model="ws.type"
            :items="['WMS', 'WFS']"
            :rules="[v => !!v || 'This field is required']"
          ></v-select>
          <v-text-field label="Name" v-model="ws.name" :rules="[v => !!v || 'This field is required']"></v-text-field>
          <v-text-field label="URL" v-model="ws.url" :rules="[v => !!v || 'This field is required']"></v-text-field>
          <v-switch label="Basic Authentication" v-model="ws.authentication"></v-switch>
          <template v-if="ws.authentication">
            <v-text-field
              label="Username"
              v-model="ws.credentials.username"
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="ws.credentials.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>
          </template>
          <v-autocomplete
            v-if="ws.type === 'WMS'"
            label="WMS Layer"
            v-model="ws.layer"
            :items="layers"
            :append-icon="icons.mdiReload"
            @click:append="loadWMSLayers"
            return-object
            item-text="Title"
            :hint="'Total: ' + layers.length"
            persistent-hint
          ></v-autocomplete>
          <v-autocomplete
            v-if="ws.type === 'WFS'"
            label="WFS Layer"
            v-model="ws.layer"
            :items="layers"
            :append-icon="icons.mdiReload"
            @click:append="loadWFSLayers"
            return-object
            item-text="Title"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close">Cancel</v-btn>
        <v-btn text color="primary" @click="create">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiReload } from '@mdi/js'
import { XMLParser } from 'fast-xml-parser'
import axios from 'axios'
export default {
  props: { showDialog: Boolean },
  inject: ['mapId'],
  components: {},
  data() {
    return {
      isValid: true,
      parser: new XMLParser(),
      icons: { mdiEyeOutline, mdiEyeOffOutline, mdiReload },
      isPasswordVisible: false,
      layers: [],
      ws: {
        name: null,
        type: '',
        url: null,
        authentication: false,
        credentials: {
          username: null,
          password: null,
        },
        layer: null,
      },
    }
  },
  watch: {
    'ws.authentication'() {
      this.ws.credentials.username = ''
      this.ws.credentials.password = ''
    },
    'ws.type'() {
      this.ws.layer = null
      this.layers = []
    },
  },
  computed: {},

  methods: {
    close() {
      this.ws = {
        name: null,
        type: null,
        url: null,
        authentication: false,
        credentials: {
          username: null,
          password: null,
        },
        layer: null,
      }
      this.$refs['form'].resetValidation()
      this.$emit('update:showDialog', false)
    },

    async loadWMSLayers() {
      await this.$refs.form.validate()
      if (!this.isValid) return
      const config = { params: { service: 'wms', version: '1.3.0', request: 'GetCapabilities' } }
      if (this.ws.authentication) {
        config.headers = {
          Authorization: `Basic ${btoa(`${this.ws.credentials.username}:${this.ws.credentials.password}`)}`,
        }
      }
      try {
        this.$loader(true)
        const { data } = await axios.get(this.ws.url, config)
        const { WMS_Capabilities } = this.parser.parse(data)
        const layers =
          WMS_Capabilities.Capability.Layer.Layer instanceof Array
            ? WMS_Capabilities.Capability.Layer.Layer
            : [WMS_Capabilities.Capability.Layer.Layer]
        this.layers = layers.map(layer => ({
          ...layer,
          EX_GeographicBoundingBox:
            layer.EX_GeographicBoundingBox || WMS_Capabilities.Capability.Layer.EX_GeographicBoundingBox,
        }))
      } catch (error) {
        console.log(error)
        this.$message('Failed to load layers', 'error')
      } finally {
        this.$loader(false)
      }
    },

    async loadWFSLayers() {
      await this.$refs.form.validate()
      if (!this.isValid) return
      const config = { params: { service: 'wfs', version: '2.0.0', request: 'GetCapabilities' } }
      if (this.ws.authentication) {
        config.headers = {
          Authorization: `Basic ${btoa(`${this.ws.credentials.username}:${this.ws.credentials.password}`)}`,
        }
      }
      try {
        this.$loader(true)
        const { data } = await axios.get(this.ws.url, config)
        const { 'wfs:WFS_Capabilities': WFS_Capabilities } = this.parser.parse(data)
        this.layers = WFS_Capabilities.FeatureTypeList.FeatureType
      } catch (error) {
        this.$message('Failed to load layers', 'error')
      } finally {
        this.$loader(false)
      }
    },

    async create() {
      await this.$refs.form.validate()
      if (!this.isValid) return
      if (!this.ws.layer) {
        this.$message('Please select a layer', 'error')
        return
      }
      this.$emit('on-create', this.ws.type === 'WMS' ? this.createWMS() : await this.createWFS())
      this.close()
    },

    createWMS() {
      const { westBoundLongitude, southBoundLatitude, northBoundLatitude, eastBoundLongitude } =
        this.ws.layer.EX_GeographicBoundingBox
      return {
        id: 'ws-' + +new Date(),
        name: this.ws.name,
        type: 'wms',
        tileUrl: this.ws.url,
        extent: [westBoundLongitude, southBoundLatitude, eastBoundLongitude, northBoundLatitude],
        credentials: this.ws.authentication
          ? { username: this.ws.credentials.username, password: this.ws.credentials.password }
          : null,
        layer: this.ws.layer.Name,
      }
    },

    async createWFS() {
      const params = {
        service: 'WFS',
        version: '2.0.0',
        typename: this.ws.layer.Name,
        outputFormat: 'application/json',
        srsname: 'EPSG:3857',
      }
      const headers = this.ws.authentication
        ? {
            Authorization: `Basic ${btoa(`${this.ws.credentials.username}:${this.ws.credentials.password}`)}`,
          }
        : {}
      try {
        this.$loader(true)
        const [features, metadata] = await Promise.all([
          axios.get(this.ws.url, { params: { ...params, request: 'GetFeature' }, headers }),
          axios.get(this.ws.url, { params: { ...params, request: 'DescribeFeatureType' }, headers }),
        ])
        return {
          id: 'ws-' + +new Date(),
          name: this.ws.name,
          type: 'wfs',
          geojson: features.data,
          url: this.ws.url,
          layer: this.ws.layer,
          credentials: this.ws.authentication
            ? { username: this.ws.credentials.username, password: this.ws.credentials.password }
            : null,
          columns: metadata.data.featureTypes[0].properties
            .filter(p => p.name !== 'geometry')
            .map(p => ({
              name: p.name,
              type: p.localType,
            })),
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
