<template>
  <portal :to="to" ref="portal">
    <VueDragResize
      v-show="active"
      v-if="ready"
      v-bind="$attrs"
      :isActive="true"
      :sticks="['br']"
      :parentW="parentWidth"
      :parentH="parentHeight"
      :x="calculatedX"
      :y="calculatedY"
      :z="calculatedZ"
      :w="w"
      :h="h"
      :minw="minw"
      :minh="minh"
      :dragHandle="dragHandle"
      parentLimitation
      preventActiveBehavior
    >
      <slot />
    </VueDragResize>
  </portal>
</template>
<script>
import VueDragResize from 'vue-drag-resize'
import { mapState } from 'vuex'
export default {
  props: {
    to: String,
    parentId: String,
    w: Number,
    h: Number,
    x: Number,
    y: Number,
    minw: Number,
    minh: Number,
    dragHandle: String,
    active: {
      type: Boolean,
      default: true,
    },
  },
  components: { VueDragResize },
  data() {
    return {
      id: Math.random().toString(36).slice(2),
      parentWidth: 0,
      parentHeight: 0,
      calculatedX: 0,
      calculatedY: 0,
      calculatedZ: 2,
      parentEl: null,
      ready: false,
      resizeObserver: new ResizeObserver(this.onParentChangeResize.bind(this)),
    }
  },
  watch: {
    active(val) {
      this.$store.commit(val ? 'map/REGISTER_MODULES' : 'map/DEREGISTER_MODULES', this.id)
    },
    modules() {
      const module = this.modules.find(module => module.id === this.id)
      if (module) {
        this.calculatedZ = module.zIndex
      }
    },
  },

  computed: {
    ...mapState('map', ['modules']),
  },

  methods: {
    onParentChangeResize() {
      this.parentWidth = (this.parentEl.clientWidth || 10) - 10
      this.parentHeight = (this.parentEl.clientHeight || 10) - 10
    },
  },

  mounted() {
    this.parentEl = document.getElementById(this.to)
    this.onParentChangeResize()
    this.calculatedX = this.x < 0 ? this.parentWidth + this.x - this.w + 10 : this.x
    this.calculatedY = this.y < 0 ? this.parentHeight + this.y - this.h + 10 : this.y
    this.ready = true
    this.resizeObserver.observe(this.parentEl)
    if (this.active) this.$store.commit('map/REGISTER_MODULES', this.id)
  },

  beforeDestroy() {
    this.resizeObserver.disconnect()
  },
}
</script>
