export const DEFAULT_TYPE = {
  Polygon: 'line',
  MultiPolygon: 'line',
  Point: 'circle',
  MultiPoint: 'circle',
  LineString: 'line',
  MultiLineString: 'line',
}

const textStyle = [
  {
    name: 'Text Value',
    key: 'text-value',
    type: 'property',
    default: '',
  },

  {
    name: 'Text Font',
    key: 'text-font',
    type: 'textFont',
    default: 'normal 10px sans-serif',
    getter: value => {
      const parts = value.split(' ')
      return [parts[0], +parts[1].replace('px', '')]
    },
    setter: value => {
      return `${value[0]} ${value[1]}px sans-serif`
    },
  },

  {
    name: 'Text Color',
    key: 'text-fill-color',
    type: 'color',
    default: '$color',
  },
]

export const DISPLAY_TYPES = [
  {
    name: 'fill',
    supportedTypes: ['Polygon', 'MultiPolygon'],
    styles: [
      {
        name: 'Fill color',
        primary: true,
        key: 'fill-color',
        type: 'color',
        default: '$color',
      },
      ...textStyle,
    ],
  },
  {
    name: 'line',
    supportedTypes: ['Polygon', 'MultiPolygon', 'LineString', 'MultiLineString'],
    styles: [
      {
        name: 'Line width',
        key: 'stroke-width',
        type: 'number',
        default: 2,
      },
      {
        name: 'Line color',
        key: 'stroke-color',
        primary: true,
        type: 'color',
        default: '$color',
      },
      ...textStyle,
    ],
  },
  {
    name: 'circle',
    supportedTypes: ['Point', 'MultiPoint'],
    styles: [
      {
        name: 'Circle color',
        key: 'circle-fill-color',
        primary: true,
        type: 'color',
        default: '$color',
      },
      {
        name: 'Circle radius',
        key: 'circle-radius',
        type: 'number',
        default: 6,
      },
      ...textStyle,
    ],
  },
]
