<template></template>
<script>
import EventBus from '@/services/event-bus'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
export default {
  inject: ['mapId', 'map'],
  props: {
    busy: Boolean,
  },
  data() {
    return {
      source: null,
      layer: null,
    }
  },
  methods: {
    addOverlay(feature, style = null, fit = false) {
      this.source.addFeature(feature)
      if (style) this.layer.setStyle(style)
      if (fit) this.map.getView().fit(feature.getGeometry().getExtent())
    },

    clearOverlay(feature = null) {
      if (feature) this.source.removeFeature(feature)
      else this.source.clear()
    },

    initLayer() {
      this.source = new VectorSource()
      this.layer = new VectorLayer({
        source: this.source,
        zIndex: 1000,
        style: {
          'fill-color': 'rgba(255, 165, 0, 0.5)',
        },
      })
      this.map.addLayer(this.layer)
    },
  },

  created() {
    this.initLayer()
    EventBus.$on(`${this.mapId}-add-overlay`, this.addOverlay)
    EventBus.$on(`${this.mapId}-clear-overlay`, this.clearOverlay)
  },

  beforeDestroy() {
    this.map.removeLayer(this.layer)
    EventBus.$off(`${this.mapId}-add-overlay`, this.addOverlay)
    EventBus.$off(`${this.mapId}-clear-overlay`, this.clearOverlay)
  },
}
</script>
