<template>
  <div class="map-control" :style="{ top, left, right, bottom }">
    <DragResize
      :active="!!selectedType"
      :parent-id="mapId"
      :to="`${mapId}-draggable-elements`"
      :w="250"
      :h="162"
      :x="10"
      :y="10"
      :z="2"
      :isResizable="false"
      dragHandle=".drag-measure"
      preventActiveBehavior
    >
      <div
        class="drag-measure"
        style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
      ></div>
      <v-card width="100%" v-if="selectedType">
        <v-card-title
          flat
          tile
          style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
          class="py-0 d-flex"
        >
          <v-icon left size="18">{{ icons.mdiRuler }}</v-icon>
          <span>{{ type[selectedType].text }}</span>

          <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-select
            @change="refresh"
            v-model="selectedUnit"
            outlined
            dense
            hide-details
            :items="type[selectedType].units"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="refresh" text> Clear </v-btn>
        </v-card-actions>
      </v-card>
    </DragResize>

    <div class="ol-control" style="position: relative">
      <v-menu absolute left top>
        <template v-slot:activator="{ on, attrs }">
          <button :disabled="busy" title="Measure" v-bind="attrs" v-on="on" icon>
            <v-icon color="black" size="20">{{ icons.mdiRulerSquare }}</v-icon>
          </button>
        </template>
        <v-list>
          <v-list-item v-for="(type, key) in type" :key="key" @click="active(key)">
            <v-list-item-title style="font-size: 15px"
              ><v-icon size="18" left>{{ type.icon }}</v-icon> <span>{{ type.text }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { mdiRulerSquare, mdiRuler, mdiRulerSquareCompass, mdiRefresh, mdiClose } from '@mdi/js'
import { Circle } from 'ol/style.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Draw from 'ol/interaction/Draw'
import DragResize from '@/components/DragResize.vue'
import { LineString, Polygon } from 'ol/geom.js'
import Overlay from 'ol/Overlay.js'
import { getArea, getLength } from 'ol/sphere.js'
import { unByKey } from 'ol/Observable.js'
export default {
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
    size: {
      type: Number,
      default: 50,
    },
    busy: Boolean,
  },
  inject: ['map', 'mapId'],
  components: { DragResize },
  data() {
    return {
      icons: { mdiRulerSquare, mdiRuler, mdiRulerSquareCompass, mdiRefresh, mdiClose },
      selectedType: null,
      selectedUnit: null,
      type: {
        area: {
          icon: mdiRuler,
          text: 'Area (Polygon)',
          units: ['m²', 'km²', 'ft²', 'acre'],
        },
        length: {
          icon: mdiRulerSquareCompass,
          text: 'Length (LineString)',
          units: ['m', 'km', 'ft'],
        },
      },

      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#1e88e5',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: '#1e88e5',
          }),
        }),
      }),
      layer: null,
      source: null,
      sketch: null,
      helpTooltipElement: null,
      helpTooltip: null,
      measureTooltipElement: null,
      measureTooltip: null,
      continuePolygonMsg: 'Left click to continue drawing the polygon, right click to cancel',
      continueLineMsg: 'Left click to continue drawing the line, right click to cancel',
      draw: null,
      overlays: [],
    }
  },

  methods: {
    active(key) {
      this.selectedType = key
      this.selectedUnit = this.type[key].units[0]
      this.$emit('update:busy', true)
      this.init()
    },

    init() {
      this.initLayer()
      this.addInteraction()
      this.createMeasureTooltip()
      this.createHelpTooltip()
      this.addEvents()
    },

    close() {
      this.selectedType = null
      this.selectedUnit = null
      this.$emit('update:busy', false)
      this.clean()
    },

    refresh() {
      this.clean()
      this.init()
    },

    clean() {
      this.map.un('pointermove', this.pointerMoveHandler)
      this.map.getViewport().removeEventListener('mouseout', this.mouseOutHandler)
      this.overlays.forEach(o => this.map.removeOverlay(o))
      this.map.removeLayer(this.layer)
      this.map.removeInteraction(this.draw)
      this.layer = null
      this.source = null
      this.draw = null
      this.overlays = []
    },

    addInteraction() {
      const type = this.selectedType === 'area' ? 'Polygon' : 'LineString'
      this.draw = new Draw({
        source: this.source,
        type: type,
        condition: evt => {
          if (evt.originalEvent.button === 0) return true
          this.draw.abortDrawing()
        },

        style: feature => {
          const geometryType = feature.getGeometry().getType()
          if (geometryType === type || geometryType === 'Point') {
            return this.style
          }
        },
      })
      this.map.addInteraction(this.draw)
    },

    pointerMoveHandler(evt) {
      if (evt.dragging) {
        return
      }
      this.map.getViewport().style.cursor = 'pointer'
      let helpMsg = 'Left click to start drawing'

      if (this.sketch) {
        const geom = this.sketch.getGeometry()
        if (geom instanceof Polygon) {
          helpMsg = this.continuePolygonMsg
        } else if (geom instanceof LineString) {
          helpMsg = this.continueLineMsg
        }
      }
      this.helpTooltipElement.innerHTML = helpMsg
      this.helpTooltip.setPosition(evt.coordinate)
      this.helpTooltipElement.classList.remove('d-none')
    },

    createHelpTooltip() {
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement)
      }
      this.helpTooltipElement = document.createElement('div')
      this.helpTooltipElement.className = 'ol-tooltip '
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
      })
      this.map.addOverlay(this.helpTooltip)
    },

    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement)
      }
      this.measureTooltipElement = document.createElement('div')
      this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure'
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        insertFirst: false,
      })
      this.map.addOverlay(this.measureTooltip)
      this.overlays.push(this.measureTooltip)
    },

    mouseOutHandler() {
      this.helpTooltipElement.classList.add('d-none')
    },

    addEvents() {
      this.map.on('pointermove', this.pointerMoveHandler)
      this.map.getViewport().addEventListener('mouseout', this.mouseOutHandler)
      let listener
      this.draw.on('drawstart', evt => {
        this.sketch = evt.feature
        let tooltipCoord = evt.coordinate
        listener = this.sketch.getGeometry().on('change', evt => {
          const geom = evt.target
          let output
          if (geom instanceof Polygon) {
            output = this.formatArea(geom)
            tooltipCoord = geom.getInteriorPoint().getCoordinates()
          } else if (geom instanceof LineString) {
            output = this.formatLength(geom)
            tooltipCoord = geom.getLastCoordinate()
          }

          this.measureTooltipElement.innerHTML = output
          this.measureTooltip.setPosition(tooltipCoord)
        })
      })

      this.draw.on('drawend', () => {
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure'
        this.measureTooltip.setOffset([0, -7])
        this.sketch = null
        this.measureTooltipElement = null
        this.createMeasureTooltip()
        unByKey(listener)
      })

      this.draw.on('drawabort', () => {
        this.sketch = null
        this.createMeasureTooltip()
        unByKey(listener)
      })
    },

    formatArea(polygon) {
      const area = getArea(polygon)
      switch (this.selectedUnit) {
        case 'm²':
          return Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>'
        case 'km²':
          return Math.round(area * 1e-6 * 100) / 100 + ' ' + 'km<sup>2</sup>'
        case 'ft²':
          return Math.round(area * 10.7639) / 100 + ' ' + 'ft<sup>2</sup>'
        case 'acre':
          return Math.round(area * 0.000247105 * 100) / 100 + ' ' + 'acre'
      }
    },

    formatLength(line) {
      const length = getLength(line)
      switch (this.selectedUnit) {
        case 'm':
          return Math.round(length * 100) / 100 + ' ' + 'm'
        case 'km':
          return Math.round(length * 0.001 * 100) / 100 + ' ' + 'km'
        case 'ft':
          return Math.round(length * 3.28084 * 100) / 100 + ' ' + 'km'
      }
    },

    initLayer() {
      this.source = new VectorSource()
      this.layer = new VectorLayer({
        source: this.source,
        style: {
          'fill-color': 'rgba(255, 255, 255, 0.2)',
          'stroke-color': '#1e88e5',
          'stroke-width': 2,
          'circle-radius': 7,
          'circle-fill-color': '#1e88e5',
        },
        zIndex: 1000,
      })
      this.map.addLayer(this.layer)
    },
  },
}
</script>

<style>
.ol-tooltip-measure {
  background-color: #1e88e5;
}
.ol-tooltip-measure:before {
  border-top: 6px solid #1e88e5;
}
</style>
