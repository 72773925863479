<template>
  <div class="map-control map-zoom" ref="mapZoom" :style="{ top, bottom, left, right }"></div>
</template>
<script>
import { Zoom } from 'ol/control.js'
export default {
  inject: ['map'],
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
  mounted() {
    this.map.addControl(
      new Zoom({
        target: this.$refs.mapZoom,
      }),
    )
  },
}
</script>

<style lang="scss">
.map-zoom {
  .ol-zoom {
    top: unset;
    left: unset;
    position: relative;
  }
}
</style>
