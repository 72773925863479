<template>
  <div class="d-flex position-relative full-height">
    <portal-target
      :id="`${id}-draggable-elements`"
      class="full-height"
      style="position: absolute; width: 100%"
      :name="`${id}-draggable-elements`"
      multiple
    ></portal-target>
    <template v-if="synchronized">
      <div :id="synchronizedMapId" class="full-height" :style="{ width: synchronized ? '50%' : 0 }"></div>
      <div class="map-divider full-height"></div>
    </template>
    <div :id="id" :style="{ width: synchronized ? '50%' : '100%' }" class="full-height">
      <component
        v-for="tool in tools"
        :key="id + '-' + tool.name"
        :is="tool.name"
        :top="tool.position.top"
        :left="tool.position.left"
        :right="tool.position.right"
        :bottom="tool.position.bottom"
        :synchronized.sync="synchronized"
        :busy.sync="busy"
      />
      <slot></slot>
    </div>
  </div>
</template>
<script>
import View from 'ol/View'
import Map from 'ol/Map'
import MousePosition from './controls/MousePosition.vue'
import Zoom from './controls/Zoom.vue'
import Scale from './controls/Scale.vue'
import FullScreen from './controls/FullScreen.vue'
import TileSwitcher from './controls/TileSwitcher.vue'
import LayerControl from './controls/layer-control/LayerControl.vue'
import Measure from './controls/Measure.vue'
import Identify from './controls/Identify.vue'
import FlashGeometry from './controls/FlashGeometry.vue'
import AddFeature from './controls/AddFeature.vue'
import Overlay from './controls/Overlay.vue'
import Geolocation from './controls/Geolocation.vue'
import ExtentControl from './controls/ExtentControl.vue'
import { transform } from 'ol/proj'
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import GeoJSON from 'ol/format/GeoJSON'
export default {
  components: {
    MousePosition,
    Zoom,
    Scale,
    FullScreen,
    TileSwitcher,
    LayerControl,
    Measure,
    Identify,
    FlashGeometry,
    AddFeature,
    Overlay,
    Geolocation,
    ExtentControl,
  },
  props: {
    id: {
      type: String,
      default: () => `map-${Math.random().toString(36).slice(2)}`,
    },
    tools: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => [0, 0],
    },
    zoom: {
      type: Number,
      default: 1,
    },
  },

  provide() {
    return { map: this.map, synchronizedMap: this.synchronizedMap, mapId: this.id }
  },

  computed: {
    synchronizedMapId() {
      return `${this.id}-sync`
    },
  },

  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      synchronized: false,
      view: new View({
        constrainResolution: true,
        maxZoom: 20,
      }),
      map: new Map({
        controls: [],
      }),
      synchronizedMap: new Map({
        controls: [],
      }),
      busy: false,
    }
  },

  watch: {
    synchronized(value) {
      if (value) {
        this.$nextTick(() => {
          this.synchronizedMap.setView(this.view)
          this.synchronizedMap.setTarget(document.getElementById(this.synchronizedMapId))
        })
      } else {
        this.synchronizedMap.setView(null)
        this.synchronizedMap.setTarget(null)
      }
    },
  },

  methods: {
    init() {
      this.view.setCenter(transform(this.center, 'EPSG:4326', 'EPSG:3857'))
      this.view.setZoom(this.zoom)
      this.map.setView(this.view)
      this.map.setTarget(document.getElementById(this.id))
      this.map.on('pointerdrag', () => {
        this.map.getViewport().style.cursor = 'grabbing'
      })
      this.map.on('pointermove', () => {
        this.map.getViewport().style.cursor = 'grab'
      })
    },

    zoomToGeometry(geometry) {
      this.view.fit(new GeoJSON().readGeometry(geometry).getExtent(), { duration: 1000 })
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style>
.map-divider {
  width: 2px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--v-primary-base);
}
</style>
