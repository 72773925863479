<template>
  <div ref="mapScale" class="map-control map-scale" :style="{ top, bottom, left, right }"></div>
</template>
<script>
import { ScaleLine } from 'ol/control.js'
export default {
  inject: ['map'],
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },

  mounted() {
    this.map.addControl(
      new ScaleLine({
        target: this.$refs.mapScale,
      }),
    )
  },
}
</script>

<style lang="scss">
.map-scale {
  .ol-scale-line {
    position: relative;
    bottom: unset;
    left: unset;
  }
}
</style>
