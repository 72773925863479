<template>
  <DragResize
    :active="!!layer"
    :parent-id="mapId"
    :to="`${mapId}-draggable-elements`"
    :w="500"
    :h="420"
    :x="10"
    :y="10"
    :z="2"
    :minw="500"
    :minh="300"
    dragHandle=".drag-table-attribute"
    preventActiveBehavior
  >
    <div
      class="drag-table-attribute"
      style="position: absolute; top: 0; height: 40px; width: calc(100% - 45px); z-index: 3; cursor: move"
    ></div>
    <v-card style="height: 100%" v-if="layer">
      <v-card-title
        flat
        tile
        style="height: 40px; border-bottom: 1px solid lightgray; cursor: move"
        class="py-0 d-flex"
      >
        <v-icon>{{ icons.mdiTable }}</v-icon>
        <span class="ml-2">Layer: {{ name }}</span>
        <v-btn small title="Hide" @click="$emit('on-close')" style="margin-left: auto" icon>
          <v-icon>{{ icons.mdiClose }}</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pb-0">
        <v-tabs grow class="elevation-0" height="35" tile v-model="mode">
          <v-tab>Feature List</v-tab>
          <v-tab>Column</v-tab>
        </v-tabs>
      </v-card-text>

      <v-card-text style="height: calc(100% - 130px)">
        <ag-grid-vue
          v-show="mode === 0"
          style="width: 100%; height: 100%"
          :class="$vuetify.theme.dark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :autoSizeStrategy="{ type: 'fitCellContents' }"
          pagination
          paginationAutoPageSize
          @grid-ready="onGridReady"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>

        <v-data-table
          v-show="mode === 1"
          style="height: 100%"
          height="100%"
          :headers="[
            { text: 'Column', value: 'name' },
            { text: 'Type', value: 'type' },
          ]"
          :items="columns"
          disable-pagination
          disable-sort
          fixed-header
          calculate-widths
          hide-default-footer
          :server-items-length="1"
          disable-filtering
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="my-2" color="primary" text @click="exportCsv">Export as CSV</v-btn>
        <v-btn class="my-2" color="primary" text @click="$emit('on-close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </DragResize>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import { getTableAttribute } from '@/api/layer'
import DragResize from '@/components/DragResize.vue'
import { mdiTable, mdiClose, mdiRefresh, mdiCrosshairs } from '@mdi/js'
import Layer from 'ol/layer/Layer'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import LayerTableAttributeAction from './LayerTableAttributeAction.vue'
import { getFeatureGeometry } from '@/api/layer'
import EventBus from '@/services/event-bus'

export default {
  inject: ['mapId', 'map'],
  props: {
    layer: Layer,
  },
  components: {
    DragResize,
    AgGridVue,
  },
  data() {
    return {
      icons: { mdiTable, mdiClose, mdiRefresh, mdiCrosshairs },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      frameworkComponents: { LayerTableAttributeAction },
      mode: 0,
    }
  },
  watch: {
    layer() {
      this.initTable()
    },
  },
  computed: {
    name() {
      return this.layer.get('name')
    },
    columns() {
      return this.layer.get('columns')
    },
    columnKeys() {
      return this.columnDefs.map(c => c.field).slice(0, -1)
    },
  },
  methods: {
    exportCsv() {
      this.gridApi.exportDataAsCsv({ fileName: `${this.name}.csv`, columnKeys: this.columnKeys })
    },
    async zoomTo(feature) {
      try {
        this.$loader(true)
        if (feature.geometry) {
          EventBus.$emit(`${this.mapId}-flash-geometry`, feature.geometry)
        } else {
          const { data } = await getFeatureGeometry(this.layer.get('id'), feature.id)
          EventBus.$emit(`${this.mapId}-flash-geometry`, data)
        }
      } finally {
        this.$loader(false)
      }
    },

    onGridReady(params) {
      const { api, columnApi } = params
      this.gridApi = api
      this.columnApi = columnApi
    },

    initTable() {
      this.setColumnDefs()
      this.setRowData()
    },

    async setRowData() {
      if (!this.layer) {
        this.rowData = []
        return
      }
      try {
        this.rowData = []
        this.$loader(true)
        if (this.layer.get('type') === 'wfs') {
          this.rowData = this.layer
            .getSource()
            .getFeatures()
            .map(f => ({
              ...f.getProperties(),
              geometry: f.getGeometry(),
            }))
        } else {
          const { data } = await getTableAttribute(this.layer.get('id'))
          this.rowData = data
        }
        setTimeout(() => this.columnApi.autoSizeAllColumns(), 300)
      } finally {
        this.$loader(false)
      }
    },

    setColumnDefs() {
      if (!this.layer) {
        this.columnDefs = []
        return
      }
      this.columnDefs = this.columns
        .map(column => {
          let filter = 'agTextColumnFilter'
          let isNumeric = false
          switch (column.type) {
            case 'bigint':
            case 'int':
            case 'float':
              isNumeric = true
              filter = 'agNumberColumnFilter'
              break
            case 'date':
            case 'datetime':
              filter = 'agDateColumnFilter'
            default:
              break
          }
          return {
            field: column.name,
            filter,
            resizable: true,
            type: isNumeric ? 'numericColumn' : undefined,
            valueGetter: ({ data }) => {
              return isNumeric ? Number(data[column.name]) : data[column.name]
            },
            filterParams: { buttons: ['reset', 'apply'] },
          }
        })
        .concat({
          field: '',
          sortable: false,
          cellClass: 'grid-cell-centered',
          cellRenderer: 'LayerTableAttributeAction',
          cellRendererParams: {
            zoomTo: data => {
              this.zoomTo(data)
            },
          },
          pinned: 'right',
        })
    },
  },
}
</script>
