<template>
  <div v-show="hidden" class="map-control">
    <div class="ol-control" style="position: relative">
      <button @click="$emit('update:hidden', false)" title="Identify feature" icon>
        <v-icon color="black" size="20">{{ icons.mdiLayers }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiLayers } from '@mdi/js'
export default {
  props: {
    hidden: Boolean,
  },
  data() {
    return {
      icons: { mdiLayers },
    }
  },
}
</script>
