<template>
  <div class="map-control map-full-screen" ref="mapFullScreen" :style="{ top, bottom, left, right }"></div>
</template>
<script>
import { FullScreen } from 'ol/control.js'
export default {
  inject: ['map'],
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
  mounted() {
    this.map.addControl(
      new FullScreen({
        target: this.$refs.mapFullScreen,
      }),
    )
  },
}
</script>

<style lang="scss">
.map-full-screen {
  .ol-full-screen {
    position: relative;
    right: unset;
    top: unset;
  }
}
</style>
