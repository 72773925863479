<template>
  <div class="map-control" :style="{ top, left, right, bottom }">
    <v-menu absolute left top>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-bind="attrs" v-on="on" :size="size" style="border: 1px solid #a8a8a8; border-radius: 5px">
          <v-img :src="providers[selectedProvider].avatar"></v-img>
        </v-avatar>
      </template>
      <v-list class="py-0" dense>
        <v-list-item-group color="primary" v-model="selectedProvider">
          <v-list-item v-for="provider in providers" :key="provider.name">
            <v-list-item-avatar>
              <v-img :src="provider.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>{{ provider.name }}</v-list-item-title>
          </v-list-item></v-list-item-group
        >
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import TileLayer from 'ol/layer/Tile'
import { OSM, XYZ } from 'ol/source'
export default {
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
    size: {
      type: Number,
      default: 45,
    },
    synchronized: Boolean,
  },
  inject: ['map', 'synchronizedMap'],

  data() {
    return {
      tileLayer: null,
      tileLayerForSynchronizedMap: null,
      selectedProvider: 0,
      providers: [
        {
          avatar: require('@/assets/images/map/map-street.jpg'),
          name: 'OSM Street Map',
          source: new OSM(),
        },
        {
          avatar: require('@/assets/images/map/satellite.jpg'),
          name: 'Google Satellite',
          source: new XYZ({
            urls: [
              'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
              'https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
              'https://mt2.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
            ],
            maxZoom: 24,
          }),
        },
      ],
    }
  },

  watch: {
    selectedProvider() {
      this.tileLayer.setSource(this.providers[this.selectedProvider].source)
      this.tileLayerForSynchronizedMap.setSource(this.providers[this.selectedProvider].source)
    },
  },

  mounted() {
    this.tileLayer = new TileLayer({
      source: this.providers[this.selectedProvider].source,
    })
    this.tileLayerForSynchronizedMap = new TileLayer({
      source: this.providers[this.selectedProvider].source,
    })
    this.map.addLayer(this.tileLayer)
    this.synchronizedMap.addLayer(this.tileLayerForSynchronizedMap)
  },
}
</script>
