<template>
  <v-dialog :value="true" max-width="500" persistent>
    <v-card>
      <v-card-title>
        <span>Style Control</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <span style="width: 120px" class="mr-3">Display type</span>
          <v-select style="width: 100%" :items="displayTypes.map(d => d.name)" v-model="displayType"></v-select>
        </div>
        <div v-for="s in styles" :key="s.key" class="d-flex align-center">
          <span style="width: 120px" class="mr-3">{{ s.name }}</span>
          <v-select
            v-if="s.type === 'property'"
            label="Property"
            style="width: 100%"
            :items="columns"
            v-model="s.value"
          ></v-select>
          <v-text-field v-if="s.type === 'number'" style="width: 100%" type="number" v-model="s.value"></v-text-field>
          <div class="d-flex" v-if="s.type === 'textFont'" style="width: 100%">
            <v-select
              style="width: 50%"
              label="Type"
              :items="['normal', 'bold', 'italic']"
              v-model="s.value[0]"
            ></v-select>
            <v-text-field label="Size(px)" type="number" :min="0" v-model="s.value[1]"></v-text-field>
          </div>
          <v-menu v-if="s.type === 'color'" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <div
                title="Color picker"
                class="elevation-2 my-5"
                v-on="on"
                v-bind:style="{ background: s.value }"
                style="width: 100%; height: 15px; border-radius: 5px; cursor: pointer"
              ></div>
            </template>
            <v-color-picker v-model="s.value" mode.sync="hexa"></v-color-picker>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="applyStyle"> Apply </v-btn>
        <v-btn color="primary" text @click="$emit('on-close')"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiTable, mdiClose, mdiRefresh, mdiCrosshairs } from '@mdi/js'
import Layer from 'ol/layer/Layer'
import { DISPLAY_TYPES } from '@/constants/map'

export default {
  inject: ['mapId'],
  props: {
    layer: Layer,
  },
  components: {},
  data() {
    return {
      icons: { mdiTable, mdiClose, mdiRefresh, mdiCrosshairs },
      showDialog: false,
      displayType: null,
      displayTypes: null,
      styles: [],
      style: {},
      columns: [],
    }
  },
  watch: {
    displayType() {
      this.setStyles()
    },
  },
  computed: {},
  methods: {
    applyStyle() {
      const style = {}
      let color
      for (const s of this.styles) {
        if (s.setter) {
          style[s.key] = s.setter(s.value)
          continue
        }
        style[s.key] = s.type === 'number' ? +s.value : s.value
        if (s.primary) color = s.value
      }
      this.layer.setStyle(style)
      this.layer.setProperties({ color, displayType: this.displayType })
      this.$emit('on-close')
    },

    setStyles() {
      const styles = this.displayTypes.find(d => d.name === this.displayType).styles
      const style = this.layer.getStyle()
      this.styles = styles.map(s => {
        const getter = s.getter || (v => v)
        const value = getter(style[s.key] || s.default)
        return {
          type: s.type,
          primary: s.primary,
          name: s.name,
          key: s.key,
          value,
          setter: s.setter,
        }
      })
    },
  },

  created() {
    const { color, displayType, vectorType, columns } = this.layer.getProperties()
    const displayTypes = DISPLAY_TYPES.filter(d => d.supportedTypes.includes(vectorType))
    for (const type of displayTypes) {
      for (const style of type.styles) {
        if (style.default === '$color') {
          style.default = color
        }
      }
    }
    this.displayTypes = displayTypes
    this.displayType = displayType
    this.columns = [{ text: 'None', value: '' }].concat(columns.map(c => ({ text: c.name, value: ['get', c.name] })))
  },
}
</script>
