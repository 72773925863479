<template>
  <DragResize
    :active="isActive"
    :parent-id="mapId"
    :to="`${mapId}-draggable-elements`"
    :w="350"
    :h="330"
    :x="10"
    :y="10"
    :z="2"
    :minw="300"
    :minh="300"
    dragHandle=".drag-add-feature"
    preventActiveBehavior
  >
    <v-card width="100%" height="100%">
      <div
        class="drag-add-feature"
        style="position: absolute; top: 0; height: 40px; width: calc(100% - 90px); z-index: 3; cursor: move"
      ></div>
      <v-card-title
        flat
        tile
        style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
        class="py-0 d-flex"
      >
        <v-icon size="18">{{ icons.mdiPlus }}</v-icon>
        <span class="ml-2">Create Feature</span>
        <div style="margin-left: auto">
          <v-icon title="Refresh" size="18" right @click="refresh">{{ icons.mdiRefresh }}</v-icon>
          <v-icon title="Undo" size="18" right @click="undo">{{ icons.mdiUndo }}</v-icon>
          <v-icon title="Close" size="18" right @click="close">{{ icons.mdiClose }}</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="mt-2 pb-0" style="height: calc(100% - 100px); overflow-y: auto">
        <v-form ref="form" v-model="isFormValid">
          <div v-for="property in properties" :key="property.value" class="mt-3">
            <v-autocomplete
              v-if="property.type === 'select'"
              v-model="form[property.value]"
              :label="property.label"
              :rules="property.rules"
              :items="property.options"
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-else-if="property.type === 'plot'"
              v-model="form[property.value]"
              :label="property.label"
              :rules="property.rules"
              :items="plots"
              dense
            ></v-autocomplete>
            <v-text-field
              v-else
              :label="property.label"
              :rules="property.rules"
              v-model="form[property.value]"
              :type="property.type"
              dense
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="createFeature" text>Create</v-btn>
        <v-btn @click="close" color="primary" text>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </DragResize>
</template>
<script>
import { mdiPlus, mdiClose, mdiUndo, mdiRefresh } from '@mdi/js'
import EventBus from '@/services/event-bus'
import { Draw, Modify, Snap } from 'ol/interaction.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import DragResize from '@/components/DragResize.vue'
import GeoJSON from 'ol/format/GeoJSON'
import { getArea } from 'ol/sphere'
import { mapState } from 'vuex'
export default {
  inject: ['mapId', 'map'],
  components: { DragResize },
  props: {
    busy: Boolean,
  },
  data() {
    return {
      icons: { mdiPlus, mdiClose, mdiUndo, mdiRefresh },
      isActive: false,
      isFormValid: true,
      geometryType: null,
      draw: null,
      source: null,
      layer: null,
      modify: null,
      properties: [],
      form: {},
      callback: null,
    }
  },
  computed: {
    ...mapState('category', ['wards']),
    plots() {
      if (!this.form.wardId) return []
      return this.wards.find(w => w.id === this.form.wardId).plots
    },
  },
  methods: {
    initData(geometryType, properties, callback) {
      this.geometryType = geometryType
      this.properties = properties
      this.callback = callback
      for (const property of this.properties) {
        this.$set(this.form, property.value, null)
      }
    },

    removeData() {
      this.geometryType = null
      this.properties = []
      this.callback = null
      this.form = {}
    },

    activate(geometryType, properties, callback) {
      if (this.busy) {
        return this.$message('Map is busy, please close current tool', 'error')
      }
      this.$emit('update:busy', true)
      this.initData(geometryType, properties, callback)
      this.initLayer()
      this.initDraw()
      this.initEvents()
      this.isActive = true
    },

    close() {
      this.clean()
      this.isActive = false
      this.$emit('update:busy', false)
    },

    pointerMoveHandler(evt) {
      if (evt.dragging) {
        return
      }
      this.map.getViewport().style.cursor = 'default'
    },

    drawEndHandler(evt) {
      this.draw.setActive(false)
    },

    clean() {
      this.removeEvents()
      this.removeDraw()
      this.removeLayer()
      this.removeData()
    },

    initEvents() {
      this.map.on('pointermove', this.pointerMoveHandler)
      this.draw.on('drawend', this.drawEndHandler)
    },

    removeEvents() {
      this.map.un('pointermove', this.pointerMoveHandler)
      this.draw.un('drawend', this.drawEndHandler)
    },

    initLayer() {
      this.source = new VectorSource()
      this.vector = new VectorLayer({
        source: this.source,
        zIndex: 1000,
        style: {
          'stroke-color': '#1e88e5',
          'stroke-width': 2,
          'circle-radius': 7,
          'circle-fill-color': '#1e88e5',
        },
      })
      this.map.addLayer(this.vector)
    },

    removeLayer() {
      this.map.removeLayer(this.vector)
      this.source = null
      this.vector = null
    },

    initDraw() {
      this.modify = new Modify({ source: this.source })
      this.draw = new Draw({
        source: this.source,
        type: this.geometryType,
      })
      this.snap = new Snap({ source: this.source })
      this.map.addInteraction(this.modify)
      this.map.addInteraction(this.draw)
      this.map.addInteraction(this.snap)
    },

    removeDraw() {
      this.map.removeInteraction(this.modify)
      this.map.removeInteraction(this.draw)
      this.map.removeInteraction(this.snap)
      this.modify = null
      this.draw = null
      this.snap = null
    },

    refresh() {
      this.source.clear()
      this.draw.setActive(true)
    },

    undo() {
      this.draw.removeLastPoint()
      this.modify.removePoint()
    },

    async createFeature() {
      await this.$refs.form.validate()
      if (!this.isFormValid) return
      try {
        const feature = this.source.getFeatures()[0]
        if (!feature) return this.$message('Please draw a feature', 'error')
        this.$loader(true)
        const geometry = JSON.parse(
          new GeoJSON().writeGeometry(feature.getGeometry(), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }),
        )
        const area = +getArea(feature.getGeometry())
        const data = await this.callback({ geometry, area, ...this.form })
        this.$loader(false)
        EventBus.$emit(`${this.mapId}-feature-created`, data)
        this.close()
      } catch (error) {
        this.$loader(false)
      }
    },
  },

  created() {
    EventBus.$on(`${this.mapId}-add-feature`, this.activate)
  },

  beforeDestroy() {
    EventBus.$off(`${this.mapId}-add-feature`, this.activate)
  },
}
</script>
