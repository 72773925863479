<template>
  <div class="map-control" :style="{ top, left, right, bottom }">
    <DragResize
      :active="isActive"
      :parent-id="mapId"
      :to="`${mapId}-draggable-elements`"
      :w="350"
      :h="380"
      :x="10"
      :y="10"
      :z="2"
      dragHandle=".drag-identify"
      preventActiveBehavior
    >
      <div
        class="drag-identify"
        style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
      ></div>
      <v-card width="100%" height="100%">
        <v-card-title
          flat
          tile
          style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
          class="py-0 d-flex"
        >
          <v-icon size="18">{{ icons.mdiCursorPointer }}</v-icon>
          <span class="ml-2">Identify</span>

          <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 pt-4">
          <v-text-field
            hint="abc"
            v-model="distance"
            dense
            hide-details
            type="number"
            label="Distance"
            :min="0"
          ></v-text-field>
          <p class="my-0 mt-2" style="font-size: 12px">Pointer: {{ coordinate | coordinateToString }}</p>
        </v-card-text>
        <v-card-text class="pb-2" style="height: calc(100% - 111px); overflow-y: auto">
          <v-treeview ref="tree-view" open-all class="property-list" dense :items="result">
            <template v-slot:append="{ item }">
              <v-icon v-if="item.zoomable" color="primary" small @click="zoomTo(item)">
                {{ icons.mdiCrosshairs }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </DragResize>

    <div class="ol-control" style="position: relative">
      <button :disabled="busy" title="Identify" icon @click="activate">
        <v-icon color="black" size="20">{{ icons.mdiCursorPointer }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiCursorPointer, mdiClose, mdiCrosshairs } from '@mdi/js'
import DragResize from '@/components/DragResize.vue'
import { Feature, Overlay } from 'ol'
import { transform } from 'ol/proj'
import { identify } from '@/api/layer'
import { identifyComplaint } from '@/api/complaint'
import { identifyPlot } from '@/api/plot'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import GeoJSON from 'ol/format/GeoJSON.js'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import EventBus from '@/services/event-bus'
import { Geometry } from 'ol/geom'
export default {
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
    size: {
      type: Number,
      default: 50,
    },
    busy: Boolean,
  },
  inject: ['map', 'mapId'],
  components: { DragResize },
  filters: {
    coordinateToString(value) {
      return transform(value, 'EPSG:3857', 'EPSG:4326')
        .map(c => c.toFixed(4))
        .join(', ')
    },
  },
  data() {
    return {
      icons: { mdiCursorPointer, mdiClose, mdiCrosshairs },
      isActive: false,
      result: [],
      helpTooltipElement: null,
      helpTooltip: null,
      coordinate: [],
      source: null,
      layer: null,
      distance: 10,
    }
  },

  methods: {
    pointerMoveHandler(evt) {
      if (evt.dragging) {
        return
      }
      this.map.getViewport().style.cursor = 'pointer'
      let helpMsg = 'Click to select feature'
      this.helpTooltipElement.innerHTML = helpMsg
      this.helpTooltip.setPosition(evt.coordinate)
      this.helpTooltipElement.classList.remove('d-none')
    },
    createHelpTooltip() {
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement)
      }
      this.helpTooltipElement = document.createElement('div')
      this.helpTooltipElement.className = 'ol-tooltip '
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
      })
      this.map.addOverlay(this.helpTooltip)
    },
    mouseOutHandler() {
      this.helpTooltipElement.classList.add('d-none')
    },
    init() {
      this.initLayer()
      this.createHelpTooltip()
      this.addEvents()
    },

    clickHandler(event) {
      this.coordinate = event.coordinate
      this.identify()
    },

    addEvents() {
      this.map.on('click', this.clickHandler)
      this.map.on('pointermove', this.pointerMoveHandler)
      this.map.getViewport().addEventListener('mouseout', this.mouseOutHandler)
    },

    close() {
      this.isActive = false
      this.$emit('update:busy', false)
      this.clean()
    },

    clean() {
      this.map.un('pointermove', this.pointerMoveHandler)
      this.map.un('click', this.clickHandler)
      this.map.getViewport().removeEventListener('mouseout', this.mouseOutHandler)
      this.map.removeLayer(this.layer)
      this.layer = null
      this.source = null
      this.result = []
    },

    activate() {
      this.isActive = true
      this.$emit('update:busy', true)
      this.init()
    },

    async identifyVector(layers) {
      if (layers.length === 0) return []
      try {
        this.$loader(true)
        const { data } = await identify(
          layers.map(layer => layer.get('id')),
          this.coordinate,
          this.distance,
        )
        return data
      } finally {
        this.$loader(false)
      }
    },

    async identify() {
      const vectorLayers = this.map.getAllLayers().filter(l => l.get('type') === 'vector' && l.getVisible())
      const resultLayers = this.map.getAllLayers().filter(l => l.get('type') === 'result' && l.getVisible())
      const complaintLayers = this.map.getAllLayers().filter(l => l.get('type') === 'complaint' && l.getVisible())
      const plotLayers = this.map.getAllLayers().filter(l => l.get('type') === 'plot' && l.getVisible())
      const wfsLayers = this.map.getAllLayers().filter(l => l.get('type') === 'wfs' && l.getVisible())
      if (vectorLayers.concat(resultLayers).concat(complaintLayers).concat(wfsLayers).concat(plotLayers).length === 0) {
        return this.$message('No layers selected', 'error')
      }
      if (Number.isNaN(parseFloat(this.distance))) {
        return this.$message('Distance must be a number', 'error')
      }
      this.transformResult([
        ...this.identifyResult(resultLayers),
        ...this.identifyWFS(wfsLayers),
        ...(await this.identifyComplaint(complaintLayers)),
        ...(await this.identifyVector(vectorLayers)),
        ...(await this.identifyPlot(plotLayers)),
      ])
      this.highlightFeatures()
    },

    async identifyComplaint(layers) {
      if (!layers.length) return []
      try {
        this.$loader(true)
        const { data } = await identifyComplaint(this.coordinate, this.distance)
        data.result = data.result.map(c => {
          return {
            Id: c.id,
            'Location Detail': c.name,
            Ward: c.ward.name,
            Status: c.complaintStatus.name,
            geometry: new GeoJSON().readGeometry(c.geometry, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }),
            'Citizen Name': c.user.name,
            'Mobile Number': c.user.mobileNumber,
            Email: c.user.email,
            'Created At': this.$options.filters.datetime(c.createdAt),
            'Updated At': this.$options.filters.datetime(c.updatedAt),
          }
        })
        return [data]
      } finally {
        this.$loader(false)
      }
    },

    async identifyPlot(layers) {
      if (!layers.length) return []
      try {
        this.$loader(true)
        const { data } = await identifyPlot(this.coordinate, this.distance)
        data.result = data.result.map(c => {
          return {
            ID: c.id,
            OBJECTID_1: c.objectid_1,
            MAPYEAR: c.mapyear,
            PLOT_NUMBER: c.kide,
            Land_Type: c.land_type,
            RMC: c.rmc,
            WARD_NO: c.ward_no,
            Shape_Leng: c.shape_leng,
            Shape_Area: c.shape_area,
            geometry: c.geometry,
          }
        })
        return [data]
      } finally {
        this.$loader(false)
      }
    },

    identifyWFS(layers) {
      const result = []
      for (const layer of layers) {
        result.push({
          id: layer.get('id'),
          name: layer.get('name'),
          result: layer
            .getSource()
            .getFeaturesAtCoordinate(this.coordinate)
            .map(f => ({
              ...f.getProperties(),
              geometry: f.getGeometry(),
            })),
        })
      }
      return result
    },

    identifyResult(layers) {
      const result = []
      for (const layer of layers) {
        result.push({
          id: layer.get('id'),
          name: layer.get('name'),
          result: layer
            .getSource()
            .getFeaturesAtCoordinate(this.coordinate)
            .map(f => {
              return {
                Id: f.get('id'),
                Ward: f.get('ward').name,
                'Plot Number': f.get('plotNumber') || '',
                Type: f.get('cdClassType').name,
                Area: f.get('area'),
                Status: f.get('cdStatus').name,
                'Land Type': f.get('landType') || '',
                // 'Village Name': f.get('villageNm') || '',
                // 'Mouza Name': f.get('mouzaNm') || '',
                // 'Anchal Name': f.get('anchalNm') || '',
                geometry: f.getGeometry(),
              }
            }),
        })
      }
      return result
    },

    transformResult(data) {
      const result = []
      for (const layer of data) {
        result.push({
          id: layer.id,
          name: layer.name,
          children: layer.result.map(r => ({
            id: r.id || r.Id || r.ID,
            name: r.id || r.Id || r.ID,
            zoomable: true,
            feature: new Feature(r.geometry instanceof Geometry ? r.geometry : new GeoJSON().readGeometry(r.geometry)),
            children: Object.keys(r)
              .filter(key => key !== 'geometry')
              .map(key => ({
                id: key,
                name: key + ': ' + r[key],
              })),
          })),
        })
      }
      this.result = result
      setTimeout(() => this.$refs['tree-view'].updateAll(true), 0)
    },

    async zoomTo(item) {
      EventBus.$emit(`${this.mapId}-flash-geometry`, item.feature.getGeometry())
    },

    highlightFeatures() {
      this.result
        .flatMap(layer => layer.children)
        .map(f => f.feature)
        .forEach(f => {
          EventBus.$emit(`${this.mapId}-flash-geometry`, f.getGeometry(), false)
        })
    },

    initLayer() {
      this.source = new VectorSource()
      this.layer = new VectorLayer({
        source: this.source,
        style: function (feature) {
          switch (feature.getGeometry().getType()) {
            case 'Polygon':
            case 'MultiPolygon':
              return new Style({
                fill: new Fill({
                  color: 'red',
                }),
              })
            case 'LineString':
            case 'MultiLineString':
              return new Style({
                stroke: new Stroke({
                  color: 'red',
                }),
              })
            case 'Point':
            case 'MultiPoint':
              return new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({
                    color: 'red',
                  }),
                }),
              })
            default:
              break
          }
        },
        zIndex: 1000,
      })
      this.map.addLayer(this.layer)
    },
  },
}
</script>
<style lang="scss">
.property-list {
  .v-treeview-node__level {
    width: 12px !important;
  }
}
</style>
