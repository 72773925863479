<template>
  <div class="map-control" :style="{ top, left, right, bottom }">
    <div class="ol-control" style="position: relative">
      <button title="Default extent" icon @click="goHome">
        <v-icon color="black" size="20">{{ icons.mdiHome }}</v-icon>
      </button>
    </div>
    <div class="ol-control" style="position: relative; margin-top: 10px">
      <button title="Previous extent" icon @click="goBack" :disabled="backStack.length === 0">
        <v-icon color="black" size="20" :disabled="backStack.length === 1">{{ icons.mdiArrowLeftBold }}</v-icon>
      </button>
    </div>
    <div class="ol-control" style="position: relative; margin-top: 10px">
      <button title="Next Extent" icon @click="goForward" :disabled="forwardStack.length === 0">
        <v-icon color="black" size="20" :disabled="forwardStack.length === 0">{{ icons.mdiArrowRightBold }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiArrowLeftBold, mdiArrowRightBold, mdiHome } from '@mdi/js'

export default {
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
  inject: ['map'],
  data() {
    return {
      icons: { mdiArrowLeftBold, mdiArrowRightBold, mdiHome },
      backStack: [],
      forwardStack: [],
      isProgrammaticMove: false,
      defaultExtent: null,
      defaultZoom: null,
    }
  },

  methods: {
    goHome() {
      this.map.getView().fit(this.defaultExtent, { maxZoom: this.defaultZoom })
    },

    goBack() {
      if (this.backStack.length > 1) {
        const previousState = this.backStack.pop()
        this.forwardStack.push(previousState)
        const { extent, zoom } = this.backStack[this.backStack.length - 1]
        this.isProgrammaticMove = true
        this.map.getView().fit(extent, { maxZoom: zoom })
      }
    },

    goForward() {
      if (this.forwardStack.length > 0) {
        const nextState = this.forwardStack.pop()
        this.backStack.push(nextState)
        const { extent, zoom } = nextState
        this.isProgrammaticMove = true
        this.map.getView().fit(extent, { maxZoom: zoom })
      }
    },

    getExtentAndZoom() {
      const view = this.map.getView()
      const extent = view.calculateExtent(this.map.getSize())
      const zoom = view.getZoom()
      return { extent, zoom }
    },
  },

  mounted() {
    setTimeout(() => {
      const { extent, zoom } = this.getExtentAndZoom()
      this.defaultExtent = extent
      this.defaultZoom = zoom
    }, 100)

    this.map.on('moveend', () => {
      if (!this.isProgrammaticMove) {
        this.backStack.push(this.getExtentAndZoom())
        this.forwardStack = []
      }
      this.isProgrammaticMove = false
    })
  },
}
</script>
