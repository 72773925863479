<template>
  <div class="d-flex justify-center align-center full-height">
    <v-btn title="Zoom to" x-small color="primary" icon @click="params.zoomTo(params.data)">
      <v-icon>{{ icons.mdiCrosshairs }}</v-icon></v-btn
    >
  </div>
</template>

<script>
import Vue from 'vue'
import { mdiCrosshairs } from '@mdi/js'
export default Vue.extend({
  data() {
    return {
      icons: { mdiCrosshairs },
    }
  },
})
</script>
