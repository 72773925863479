<template></template>
<script>
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import EventBus from '@/services/event-bus'
import { Geometry } from 'ol/geom'
export default {
  inject: ['map', 'mapId'],
  data() {
    return {
      source: null,
      flashLayer: null,
    }
  },
  methods: {
    flash(geometry, fit = true) {
      if (!(geometry instanceof Geometry)) geometry = new GeoJSON().readGeometry(geometry)
      if (fit) this.map.getView().fit(geometry.getExtent())
      const feature = new Feature(geometry)
      this.source.addFeature(feature)
      setTimeout(() => this.source.removeFeature(feature), 500)
    },

    initFlashLayer() {
      this.source = new VectorSource()
      this.flashLayer = new VectorLayer({
        source: this.source,
        opacity: 0.5,
        style: function (feature) {
          switch (feature.getGeometry().getType()) {
            case 'Polygon':
            case 'MultiPolygon':
              return new Style({
                fill: new Fill({
                  color: 'red',
                }),
              })
            case 'LineString':
            case 'MultiLineString':
              return new Style({
                stroke: new Stroke({
                  color: 'red',
                }),
              })
            case 'Point':
            case 'MultiPoint':
              return new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({
                    color: 'red',
                  }),
                }),
              })
            default:
              break
          }
        },
        zIndex: 1000,
      })
      this.map.addLayer(this.flashLayer)
    },
  },
  created() {
    this.initFlashLayer()
    EventBus.$on(`${this.mapId}-flash-geometry`, this.flash)
  },
  beforeDestroy() {
    EventBus.$off(`${this.mapId}-flash-geometry`, this.flash)
    this.map.removeLayer(this.flashLayer)
  },
}
</script>
