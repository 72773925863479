<template>
  <Fragment>
    <LayerToggler :hidden.sync="hidden" :style="{ top, bottom, left, right }" />
    <DragResize
      :active="!hidden"
      :parent-id="mapId"
      :to="`${mapId}-draggable-elements`"
      :w="360"
      :h="330"
      :y="10"
      :z="2"
      :x="-10"
      :minw="360"
      :minh="330"
      dragHandle=".drag-layer-control"
      preventActiveBehavior
    >
      <div
        class="drag-layer-control"
        style="position: absolute; top: 0; height: 40px; width: calc(100% - 45px); z-index: 3; cursor: move"
      ></div>
      <v-card width="100%" height="100%" flat tile>
        <v-card-title style="height: 40px; border-bottom: 1px solid lightgray; cursor: move" class="py-0 d-flex">
          <v-icon>{{ icons.mdiLayers }}</v-icon>
          <span class="ml-2">Layer Control</span>
          <v-btn small title="Hide" @click="hidden = true" style="margin-left: auto" icon>
            <v-icon>{{ icons.mdiChevronRight }}</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text class="py-2">
          <v-radio-group class="mt-0 py-0" dense hide-details v-model="compareMode" row>
            <v-radio label="Overlay" value="overlay"></v-radio>
            <v-radio label="Slide" value="slide"></v-radio>
            <v-radio label="Sync" value="sync"></v-radio>
            <v-icon title="Create Web Service Layer" class="ml-auto" @click="showCreateWS = true">{{
              icons.mdiLayersPlus
            }}</v-icon>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="py-3" style="height: calc(100% - 85px)">
          <Container
            v-if="layers.length > 0"
            @drop="onDrop"
            drag-handle-selector=".handle"
            style="height: 100%; overflow-y: auto"
          >
            <Draggable v-for="layer in layers" :key="layer.id">
              <Layer
                class="mb-2"
                :layer="layer"
                :compare-mode="compareMode"
                :ref="layer.get('uid')"
                @on-delete="deleteLayer(layer)"
                @on-zoom="zoomToLayer(layer)"
                @on-change-position="changeLayerPosition(layer, $event)"
                @on-change-synchorized="changeSynchorized(layer, $event)"
                @on-show-table-attribute="currentLayerShowedTableAttribute = layer"
                @on-show-style-control="currentLayerShowedOnStyleControl = layer"
              />
            </Draggable>
          </Container>
          <p class="text-center my-0 pb-1" v-else>No layer added</p>
        </v-card-text>
      </v-card>
    </DragResize>

    <LayerTableAttribute
      :layer="currentLayerShowedTableAttribute"
      @on-close="currentLayerShowedTableAttribute = null"
    />
    <LayerStyle
      v-if="currentLayerShowedOnStyleControl"
      :layer="currentLayerShowedOnStyleControl"
      @on-close="currentLayerShowedOnStyleControl = null"
    />
    <LayerCreateWebService :show-dialog.sync="showCreateWS" @on-create="addLayer($event)" />
  </Fragment>
</template>
<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import Layer from './Layer'
import LayerTableAttribute from './LayerTableAttribute'
import LayerToggler from './LayerToggler'
import LayerCreateWebService from './LayerCreateWebService'
import { mdiLayers, mdiChevronRight, mdiLayersPlus } from '@mdi/js'
import Swipe from 'ol-ext/control/Swipe'
import LayerStyle from './LayerStyle.vue'
import { transformExtent } from 'ol/proj'
import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS.js'
import { XYZ } from 'ol/source'
import VectorTileLayer from 'ol/layer/VectorTile'
import VectorTileSource from 'ol/source/VectorTile.js'
import MVT from 'ol/format/MVT.js'
import randomMaterialColor from 'random-material-color'
import { DEFAULT_TYPE, DISPLAY_TYPES } from '@/constants/map'
import EventBus from '@/services/event-bus'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import DragResize from '@/components/DragResize.vue'
export default {
  components: {
    Layer,
    Container,
    Draggable,
    LayerTableAttribute,
    LayerToggler,
    LayerStyle,
    DragResize,
    LayerCreateWebService,
  },
  inject: ['map', 'synchronizedMap', 'mapId'],
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },

  watch: {
    layers() {
      this.updateOrder()
    },

    compareMode(newValue, oldValue) {
      this.cleanOldCompareMode(oldValue)
      this.activeCompareMode(newValue)
    },
  },

  data() {
    return {
      layers: [],
      icons: { mdiLayers, mdiChevronRight, mdiLayersPlus },
      hidden: false,
      compareMode: 'overlay',
      swipeControl: new Swipe(),
      currentLayerShowedTableAttribute: null,
      currentLayerShowedOnStyleControl: null,
      showCreateWS: false,
    }
  },

  methods: {
    onDrop(dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return
      const result = [...this.layers]
      this.layers = []
      this.$nextTick(() => {
        let itemToAdd = payload
        if (removedIndex !== null) {
          itemToAdd = result.splice(removedIndex, 1)[0]
        }
        if (addedIndex !== null) {
          result.splice(addedIndex, 0, itemToAdd)
        }
        this.layers = result
      })
    },

    createRasterLayer(extent, minZoom, maxZoom, url) {
      const source = new XYZ({ url, maxZoom, minZoom })
      const rasterLayer = new TileLayer({ extent, source })
      rasterLayer.setProperties({ color: 'var(--v-primary-base)' })
      return rasterLayer
    },

    createDefaultStyle(type, color) {
      const displayType = DISPLAY_TYPES.find(t => t.name === DEFAULT_TYPE[type])
      const style = JSON.parse(JSON.stringify(displayType.styles).replaceAll(/\$color/g, color)).reduce(
        (s, c) => ({ ...{ [c.key]: c.default }, ...s }),
        {},
      )
      return { color, style, displayType: displayType.name }
    },

    createVectorLayer(extent, minZoom, maxZoom, tileUrl, vectorType, styleConfig) {
      const source = new VectorTileSource({ format: new MVT(), url: tileUrl, minZoom, maxZoom })
      const mapLayer = new VectorTileLayer({ renderMode: 'hybrid', extent, source, declutter: true })
      const { color, style, displayType } = styleConfig.style
        ? styleConfig
        : this.createDefaultStyle(vectorType, styleConfig.color || randomMaterialColor.getColor())
      mapLayer.setStyle(style)
      mapLayer.setProperties({ color, displayType, vectorType })
      return mapLayer
    },

    createResultLayer(geojson, vectorType, styleConfig) {
      const source = new VectorSource({ features: this.getFeaturesFromGeoJSON(geojson) })
      const mapLayer = new VectorLayer({ source })
      const { color, style, displayType } = styleConfig
      mapLayer.setStyle(style)
      mapLayer.setProperties({ color, displayType, vectorType })
      return mapLayer
    },

    createWMSLayer(extent, url, credentials, layer) {
      this.showCreateWS = false
      const source = new TileWMS({
        url,
        params: { LAYERS: layer, TILED: true, FORMAT: 'image/png' },
        serverType: 'geoserver',
        transition: 0,
        tileLoadFunction: (imageTile, src) => {
          const client = new XMLHttpRequest()
          client.responseType = 'blob'
          client.open('GET', src)
          if (credentials) {
            client.setRequestHeader('Authorization', `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`)
          }
          client.onload = () => {
            const url = URL.createObjectURL(client.response)
            const img = imageTile.getImage()
            img.addEventListener('load', function () {
              URL.revokeObjectURL(url)
            })
            img.src = url
          }
          client.send()
        },
      })
      const wmsLayer = new TileLayer({ extent, source })
      wmsLayer.setProperties({ color: 'var(--v-primary-base)' })
      return wmsLayer
    },

    createWFSLayer(geojson) {
      const features = this.getFeaturesFromGeoJSON(geojson, 'EPSG:3857')
      const source = new VectorSource({ features })
      const mapLayer = new VectorLayer({ source })
      const feature = features[0]
      const vectorType = feature.getGeometry().getType().toString()
      const { color, style, displayType } = this.createDefaultStyle(
        features[0].getGeometry().getType().toString(),
        randomMaterialColor.getColor(),
      )
      mapLayer.setStyle(style)
      mapLayer.setProperties({ color, displayType, vectorType })
      this.showCreateWS = false
      return mapLayer
    },

    getFeaturesFromGeoJSON(geojson, dataProjection = 'EPSG:4326', featureProjection = 'EPSG:3857') {
      return new GeoJSON().readFeatures(geojson, { dataProjection, featureProjection })
    },

    addLayer(layer, style = {}, legend = [], actions = null) {
      setTimeout(() => {
        let mapLayer
        const { extent, minZoom, maxZoom, tileUrl, vectorType, geojson, ...properties } = layer
        const transformedExtent = extent ? transformExtent(extent, 'EPSG:4326', 'EPSG:3857') : null
        switch (layer.type) {
          case 'raster':
            actions = actions || ['download']
            mapLayer = this.createRasterLayer(transformedExtent, minZoom, maxZoom, tileUrl)
            break
          case 'vector':
            actions = actions || ['table-attribute', 'style-control', 'download']
            mapLayer = this.createVectorLayer(transformedExtent, minZoom, maxZoom, tileUrl, vectorType, style)
            break
          case 'wms':
            actions = actions || []
            mapLayer = this.createWMSLayer(transformedExtent, tileUrl, layer.credentials, layer.layer)
            break
          case 'wfs':
            actions = actions || ['table-attribute', 'style-control', 'download']
            mapLayer = this.createWFSLayer(geojson)
            break
          case 'complaint':
          case 'plot':
            actions = actions || ['style-control']
            mapLayer = this.createVectorLayer(transformedExtent, minZoom, maxZoom, tileUrl, vectorType, style)
            break
          case 'result':
            actions = actions || []
            mapLayer = this.createResultLayer(geojson, vectorType, style || {})
            break
        }
        mapLayer.setProperties({ ...properties, actions, legend })
        this.layers.unshift(mapLayer)
        EventBus.$emit(`${this.mapId}-layer-added`, mapLayer)
        this.map.addLayer(mapLayer)
        this.zoomToLayer(mapLayer)
      }, 10) // For smooth UI
    },

    updateOrder() {
      for (const idx in this.layers) {
        this.layers[idx].setZIndex(this.layers.length - idx)
      }
    },

    deleteLayer(layer) {
      if (typeof layer !== 'object') {
        layer = this.layers.find(l => l.get('id') === layer)
        if (!layer) return
      }
      this.map.removeLayer(layer)
      if (this.compareMode === 'sync') {
        this.synchronizedMap.removeLayer(layer)
      }
      const index = this.layers.indexOf(layer)
      if (index > -1) {
        this.layers.splice(index, 1)
      }
      EventBus.$emit(`${this.mapId}-layer-deleted`, layer)
    },

    zoomToLayer(layer) {
      if (layer.getSource().isEmpty && layer.getSource().isEmpty()) {
        return this.$message('This layer is empty', 'error')
      }
      this.map.getView().fit(layer.getExtent() || layer.getSource().getExtent(), { duration: 0 })
    },

    resetLayerVisible() {
      this.layers.forEach(layer => {
        layer.setVisible(true)
      })
    },

    cleanOldCompareMode(mode) {
      this.resetLayerVisible()
      switch (mode) {
        case 'slide':
          this.swipeControl.removeLayers()
          this.map.removeControl(this.swipeControl)
          break
        case 'sync':
          this.layers.forEach(layer => {
            this.synchronizedMap.removeLayer(layer)
            this.map.removeLayer(layer)
            this.map.addLayer(layer)
          })
          this.$emit('update:synchronized', false)
          break
      }
    },

    activeCompareMode(mode) {
      switch (mode) {
        case 'slide':
          this.map.addControl(this.swipeControl)
          break
        case 'sync':
          this.$emit('update:synchronized', true)
          break
      }
    },

    changeSynchorized(layer, value) {
      if (value) {
        this.synchronizedMap.addLayer(layer)
      } else {
        this.synchronizedMap.removeLayer(layer)
      }
    },

    changePositionInSlideMode(layer, position) {
      layer.setVisible(true)
      this.swipeControl.removeLayer(layer)
      switch (position) {
        case 'L':
          this.swipeControl.addLayer(layer)
          break
        case 'R':
          this.swipeControl.addLayer(layer, true)
          break
        case '':
          layer.setVisible(false)
          break
      }
    },

    changePositionInSyncMode(layer, position) {
      this.synchronizedMap.removeLayer(layer)
      this.map.removeLayer(layer)
      this.$nextTick(() => {
        switch (position) {
          case 'L':
            this.synchronizedMap.addLayer(layer)
            break
          case 'R':
            this.map.addLayer(layer)
            break
          case 'LR':
            this.synchronizedMap.addLayer(layer)
            this.map.addLayer(layer)
            break
        }
      })
    },

    changeLayerPosition(layer, position) {
      const transformedPostion = JSON.parse(JSON.stringify(position)).sort().join('')
      switch (this.compareMode) {
        case 'slide':
          this.changePositionInSlideMode(layer, transformedPostion)
          break
        case 'sync':
          this.changePositionInSyncMode(layer, transformedPostion)
          break
      }
    },

    updateVectorLayer(id, geojson) {
      const layer = this.layers.find(l => l.get('id') === id)
      if (!layer) return
      const source = layer.getSource()
      source.clear()
      source.addFeatures(this.getFeaturesFromGeoJSON(geojson))
    },

    updateVectorTileLayer(id, tileUrl, extent) {
      const layer = this.layers.find(l => l.get('id') === id)
      if (!layer) return
      const source = layer.getSource()
      layer.setExtent(transformExtent(extent, 'EPSG:4326', 'EPSG:3857'))
      source.setUrl(tileUrl)
    },
  },

  created() {
    EventBus.$on(`${this.mapId}-add-layer`, this.addLayer)
    EventBus.$on(`${this.mapId}-delete-layer`, this.deleteLayer)
    EventBus.$on(`${this.mapId}-update-vector-layer`, this.updateVectorLayer)
    EventBus.$on(`${this.mapId}-update-vector-tile-layer`, this.updateVectorTileLayer)
  },

  destroyed() {
    EventBus.$off(`${this.mapId}-add-layer`, this.addLayer)
    EventBus.$off(`${this.mapId}-delete-layer`, this.deleteLayer)
    EventBus.$off(`${this.mapId}-update-vector-layer`, this.updateVectorLayer)
    EventBus.$off(`${this.mapId}-update-vector-tile-layer`, this.updateVectorTileLayer)
  },
}
</script>
